import firebase from 'firebase/app';
import moment from 'moment';

const collName = 'gesContrats';
const gesContrats = {
    namespaced: true,
    state: {
        gesContrats: [],
    },
    getters: {
        getgesContrats: (state) => {
            return state.gesContrats;
        },
    },
    mutations: {
        SET_gesContrats(state, gesContrats) {
            gesContrats.sort((a, b) => {
                return new Date(moment(b.dateEnvoi, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm')) - new Date(moment(a.dateEnvoi, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm'));
            });
            state.gesContrats = gesContrats;
        },
        ADD_gesContrats(state, order) {
            state.gesContrats.push(order);
            state.gesContrats.sort((a, b) => Date.parse(b.dateEnvoi) - Date.parse(a.dateEnvoi));
        },
        UPDATE_ORDER(state, payload) {
            Object.assign(state.gesContrats[payload.index], payload.order);
        },
        REMOVE_gesContrats(state, order) {
            const index = state.gesContrats.indexOf(order);
            state.gesContrats.splice(index, 1);
        }
    },

    actions: {

        async loadgesContrats({ commit }) {
            let keySearch = "";
            if (localStorage.getItem("role") == "Superviseur (Directeur)") {
                keySearch = "Validé";
            }
            let querySnapshot;
            if (keySearch != "") {
                querySnapshot = await firebase.firestore().collection(collName).where("status", "==", keySearch).orderBy('dateEnvoi', 'desc').get();
            } else {
                querySnapshot = await firebase.firestore().collection(collName).orderBy('dateEnvoi', 'desc').get();
            }
            let order;
            let gesContrats = querySnapshot.docs.map(function (doc) {
                order = doc.data();
                order.id = doc.id;
                return order;
            });
            commit('SET_gesContrats', gesContrats);
        },

        async addgesContrats({ commit }, order) {
            let actualId = 0;
            await firebase.firestore()
                .collection("stats")
                .get().then((snapshot) => {
                    const documents = snapshot.docs.map((doc) => doc.data());
                    console.log(documents[0]['gesContratsCount'])
                    actualId = documents[0]['gesContratsCount'] + 1;
                    console.log(actualId)
                    if (actualId < 10) {
                        actualId = '000' + actualId.toString();
                    }
                    else if (actualId < 100) {
                        actualId = '00' + actualId.toString();
                    }
                    else if (actualId < 1000) {
                        actualId = '0' + actualId.toString();
                    }
                });
            var m = new Date();
            var dateString =
                ("0" + m.getUTCDate()).slice(-2) + "-" +
                ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
                m.getUTCFullYear() + " " +
                ("0" + m.getUTCHours()).slice(-2) + ":" +
                ("0" + m.getUTCMinutes()).slice(-2);
            order['dateDebut'] = moment(order['dateDebut'], 'YYYY-MM-DD').format('DD-MM-YYYY HH:mm');
            order['dateFin'] = moment(order['dateFin'], 'YYYY-MM-DD').format('DD-MM-YYYY HH:mm');

            order['userId'] = firebase.auth().currentUser.uid;
            order['userEmail'] = firebase.auth().currentUser.email;
            order['userName'] = firebase.auth().currentUser.displayName;
            order['dateEnvoi'] = dateString;
            order['idForm'] = 'CONTRA' + actualId;


            const docRef = await firebase.firestore().collection(collName).add(order);
            order.id = docRef.id;

            commit('ADD_gesContrats', order);

            if (order['imagesJoined'] != "" && order['imagesJoined'] != []) {
                await firebase.firestore().collection("gesContrats").doc(docRef.id).update({
                    'imagesJoined': firebase.firestore.FieldValue.arrayUnion(...order['imagesJoined']),
                });
            }

            if (order['documentsJoined'] != "" && order['documentsJoined'] != []) {
                await firebase.firestore().collection("gesContrats").doc(docRef.id).update({
                    'documentsJoined': firebase.firestore.FieldValue.arrayUnion(...order['documentsJoined']),
                });
            }

            var m1 = new Date();
            var dateString1 =
                ("0" + m1.getUTCDate()).slice(-2) + "-" +
                ("0" + (m1.getUTCMonth() + 1)).slice(-2) + "-" +
                m1.getUTCFullYear() + " " +
                ("0" + m1.getUTCHours()).slice(-2) + ":" +
                ("0" + m1.getUTCMinutes()).slice(-2);
            let audit = {};
            audit["date"] = dateString1;
            audit["event"] = 'Contrat';
            audit["user"] = localStorage.getItem("nom");
            audit["userId"] = localStorage.getItem("userId");
            audit["tel"] = localStorage.getItem("tel");
            audit["role"] = localStorage.getItem("role");
            firebase.firestore().collection("audit").add(audit);

         
            await firebase.firestore().collection("stats").doc("D2zmmdQjpRwFtHunf1zo").update({
                'gesContratsCount': firebase.firestore.FieldValue.increment(1),
            });
        },

        async updategesContrats({ commit }, payload) {
            var m = new Date();
            var dateString =
                ("0" + m.getUTCDate()).slice(-2) + "-" +
                ("0" + (m.getUTCMonth() + 1)).slice(-2) + "-" +
                m.getUTCFullYear() + " " +
                ("0" + m.getUTCHours()).slice(-2) + ":" +
                ("0" + m.getUTCMinutes()).slice(-2);
            if (payload.order["commentaireAdmin"] != '') {
                payload.order["roleCommentateur"] = localStorage.getItem("role");
                payload.order["commentedBy"] = localStorage.getItem("nom");
                payload.order["dateComment"] = dateString;
            }

            payload.order['dateDebut'] = moment(payload.order['dateDebut'], 'YYYY-MM-DD').format('DD-MM-YYYY HH:mm');
            if (payload.order['dateDebut'] == "Invalid date") {
                payload.order['dateDebut'] = dateString;
            }

            payload.order['dateFin'] = moment(payload.order['dateFin'], 'YYYY-MM-DD').format('DD-MM-YYYY HH:mm');
            if (payload.order['dateFin'] == "Invalid date") {
                payload.order['dateFin'] = dateString;
            }

            await firebase.firestore().collection(collName).doc(payload.order.id).set(payload.order);
            commit('UPDATE_ORDER', payload);
        },
        async removegesContrats({ commit }, order) {
            await firebase.firestore().collection(collName).doc(order.id).delete();
            commit('REMOVE_gesContrats', order);
        }
    }
}

export default gesContrats;